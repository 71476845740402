import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { graphql } from "gatsby";
import { Bio } from "../components/bio";
import { Layout } from "../components/layout";
import { SEO } from "../components/seo";
import SiteNav from "../components/siteNav";
import { FadeLink } from "../components/link";
import Img from "gatsby-image";
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout location={props.location} title={props.data.site.siteMetadata.title} mdxType="Layout">
  <SEO title="About" keywords={[`blog`, `akshay kumar`, `akshaykmr`, `about`, `software engineer`, `oorja`, 'kayako', 'shuttl', 'datadog']} mdxType="SEO" />
      <SiteNav current={props.location} mdxType="SiteNav" />
      <p>{`Hey! I’m Akshay 👋`}</p>
      <p>
I build software that users love and businesses rely on. I've worked in startups and product teams, creating scalable systems that drive growth and adapt to business needs.
      </p>
      <p>I care deeply about my craft. No buzzwords or fluff - just solid work, good people, and getting things done.
      </p>
      <p>
Outside work, I play football, explore new cities, and love picking up new hobbies. Always curious, always learning.
      </p>
      <ul>
  <li>
  <FadeLink to={`/highlights`} mdxType="FadeLink"> Some highlights</FadeLink> from my professional experience and tinkerings.
  </li>
  <li>
  <FadeLink to={`/blog`} mdxType="FadeLink"> Read my engineering posts 📝</FadeLink>
  </li>
      </ul>
      <p>{`I am also experimenting with real-time collaboration with `}<a parentName="p" {...{
          "href": "https://oorja.io"
        }}>{`oorja.io`}</a></p>
      <div>
  Always happy to hear from you.
  <br />
  <a href="mailto:akshay.kmr4321+website@gmail.com?Subject=Hello%20Akshay" target="_top">Say hi</a>, or find me on <a target="_blank" href="https://github.com/akshayKMR">GitHub</a>.
      </div>
      <br />
    </Layout>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      